<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    header="Proveedor Detalle"
    :modal="true"
    @hide="ocultalDialog"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <span>NOMBRE COMPLETO <span class="p-invalid">*</span></span>
          <InputText
            v-model.trim="data_proveedor.nombre_completo"
            required="true"
            autofocus
            @change="replicarNombreCompleto"
          />
          <small class="p-invalid" v-if="errors.nombre_completo">{{
            errors.nombre_completo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span>TIPO PROVEEDOR:</span>
          <Dropdown
            v-model="data_proveedor.tipo_proveedor"
            :options="tproveedores"
            optionLabel="label"
            placeholder="Seleccione tipo Proveedor"
          >
            <template #value="slotProps">
              <span>{{ slotProps.value.label }}</span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-2">
          <span>CI/NIT:</span>
          <InputText v-model="data_proveedor.ci_nit" />
          <small class="p-invalid" v-if="errors.ci_nit">{{
            errors.ci_nit[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span>TEL&Oacute;FONO:</span>
          <InputText v-model="data_proveedor.telefono" />
          <small class="p-invalid" v-if="errors.telefono">{{
            errors.telefono[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <span>CONTACTO:</span>
          <InputText v-model="data_proveedor.contacto" />
          <small class="p-invalid" v-if="errors.contacto">{{
            errors.contacto[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <span>CEL. CONTACTO:</span>
          <InputText v-model="data_proveedor.contacto_telf" />
          <small class="p-invalid" v-if="errors.contacto_telf">{{
            errors.contacto_telf[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span>NOMBRE CHEQUE:</span>
          <InputText v-model="data_proveedor.nombre_cheque" />
          <small class="p-invalid" v-if="errors.nombre_cheque">{{
            errors.nombre_cheque[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span>NOMBRE LCV:</span>
          <InputText v-model="data_proveedor.nombre_lcv" />
          <small class="p-invalid" v-if="errors.nombre_lcv">{{
            errors.nombre_lcv[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span>ESTADO:</span>
          <Dropdown
            v-model="data_proveedor.estado"
            :disabled="!data_proveedor.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado"
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          >CAMPOS REQUERIDOS: <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultalDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        :label="data_proveedor.id ? 'ACTUALIZAR' : 'GUARDAR'"
        icon="pi pi-check"
        class="p-button-info p-button-lg"
        @click="guardarProveedor"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ProveedorService from "@/service/ProveedorService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    proveedor: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  proveedorService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_proveedor: this.proveedor,
      enviado: false,
      errors: {},
      tproveedores: [
        { label: "Juridico", value: 0 },
        { label: "Natural", value: 1 },
      ],
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  created() {
    this.proveedorService = new ProveedorService();
  },
  methods: {
    replicarNombreCompleto() {
      this.data_proveedor.nombre_cheque = this.data_proveedor.nombre_lcv =
        this.data_proveedor.nombre_completo;
    },
    ocultalDialog() {
      this.errors = {};
      this.mostrarModal = false;
      /* this.$emit("actualizarListado"); */
      this.$emit("closeModal");
    },
    guardarProveedor() {
      this.errors = {};
      this.enviado = true;
      if (this.proveedor.id) {
        let proveedor_enviar = {
          ...this.data_proveedor,
          estado: this.data_proveedor.estado.value,
          tipo_proveedor: this.data_proveedor.tipo_proveedor.value,
        };
        //editar Proveedor
        this.proveedorService
          .updatedProveedor(proveedor_enviar)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultalDialog();
            }
            this.enviado = false;
          });
      } else {
        //Creando Nueva Proveedor
        let tmp = this.data_proveedor;

        this.data_proveedor = {
          ...this.data_proveedor,
          estado: this.data_proveedor.estado.value,
          tipo_proveedor: this.data_proveedor.tipo_proveedor.value,
        };
        this.proveedorService
          .sendProveedorNueva(this.data_proveedor)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_proveedor = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });
              this.$emit("actualizarListado", data.proveedor);
              this.ocultalDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    proveedor(val) {
      this.data_proveedor = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
